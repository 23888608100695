import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import LoadingData from '../../../components/loading';

export default function HomeroomStudentHabitOnGoing({ habit_student_selected, }){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [on_goingHabit_arr, set_on_goingHabit_arr] = useState([])

	useEffect(() => {
		get_on_going_habit()
	}, [])

	async function get_on_going_habit(){
		var response = await base.request(
			'/habit/tracker?class_id=' + query.get('id') +
			'&type=to_be_completed' +
			'&search=' +
			'&class_student_id=' + habit_student_selected.class_student_id
		)

		if(response != null){
			if(response.status == 'success'){
				for (var data of response.data.data){
					var start_date = base.moment(data.start_date)
					var end_date = base.moment(data.end_date)
					data.duration_day = end_date.diff(start_date, 'days')

					var arr = []
					var arr_temp = []
					var counter = 0
					while(start_date.isSameOrBefore(end_date)){
						var flag = false
						if(counter == 0)
							arr_temp = []

						for(let history of data.history){
							var history_date = base.moment(history.done_at)
							if(start_date.isSame(history_date, 'date')){
								flag = true
								break
							}
						}

						arr_temp.push({
							date : base.moment(start_date).format('DD'),
							is_done : flag,
						})
						start_date.add(1, 'd')
						counter++
						if(counter >= 10 || start_date.isSame(end_date)){
							arr.push(arr_temp)
							counter = 0
						}
					}

					data.day_arr = arr
					data.is_show = true
				}

				set_on_goingHabit_arr(response.data.data)
			}
		}
	}

	async function toggleHabitOnGoing(index){
		var arr = JSON.parse(JSON.stringify(on_goingHabit_arr))
		for(var x in arr){
			if(index != x)
				arr[x].is_show = false
		}
		arr[index].is_show = !arr[index].is_show
		set_on_goingHabit_arr(arr)
	}

	return(
		<div className=''>
      <div className="card rounded shadow-sm">
        <div className={"card-body p-3"}>

          <div className='row'>
            <div className='col-auto'>
              <img src={habit_student_selected.image_display} style={{height : '4rem', width : '4rem', aspectRatio : 1, borderRadius : '4rem'}} />
            </div>

            <div className='col-auto pl-0 d-flex align-items-center'>
              <h6 className='m-0 text-secondary' style={{fontFamily : 'InterBold'}}>{habit_student_selected.name}</h6>
            </div>
          </div>

        </div>
      </div>

      <div className='row mt-5'>
        {
          on_goingHabit_arr.map((data, index)=>
            <div className='col-12 col-lg-6' key={index}>
              <div className="card rounded shadow-sm">
                <div className={"card-body p-3"}>

                  <div className='px-4 py-1'>
                    <div className='row' style={{cursor : 'pointer'}} onClick={()=>toggleHabitOnGoing(index)}>
                      <div className='col-auto'>
                        <h2 className='m-0'>
													<i className="bi bi-bookmark-check-fill text-primary mr-3"/>
												</h2>
                      </div>

                      <div className='col p-0 d-flex align-items-center'>
                        <h5 className='m-0'>{data.name}</h5>
                      </div>

                      <div className='col d-flex align-items-center justify-content-end'>
                        <i className={"fas fa-chevron-" + (data.is_show ? 'up' : 'down')}></i>
                      </div>
                    </div>

                    {
                      data.is_show &&
                      <div>
                        <div className='row mt-3 p-0'>
                          <div className='col'>
                            <p className='m-0 text-primary'>Habit Category</p>
                            <p className='m-0 text-primary' style={{fontFamily : 'InterBold'}}>{data.category_habit.name}</p>
                          </div>

                          <div className='col'>
                            <p className='m-0 text-primary'>Duration of Habit</p>
                            <p className='m-0 text-primary' style={{fontFamily : 'InterBold'}}>{base.moment(data.start_date).format('DD MMM')} - {base.moment(data.end_date).format('DD MMM')}</p>
                          </div>

                          <div className='col'>
                            <p className='m-0 text-primary'>Goals</p>
                            <p className='m-0 text-primary' style={{fontFamily : 'InterBold'}}>{data.history.length}/{data.duration_day}</p>
                          </div>
                        </div>

                        <div className='table-responsive mt-3 p-0'>
                          <table className="table table-borderless">
                            <tbody>
                              {
                                data.day_arr.map((data_day, index_day)=>
                                  <tr key={index_day}>
                                    {
                                      data_day.map((data_day1, index_day1)=>
                                        <td className='text-center align-middle' key={index_day1}>
                                          {
                                            data_day1.is_done ?
                                            <h3 className='m-0'>
																							<i className="bi bi-check-circle-fill text-secondary"/>
																						</h3>
                                            :
                                            <p className='m-0'>{data_day1.date}</p>
                                          }
                                        </td>
                                      )
                                    }
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>

                </div>
              </div>
            </div>
          )
        }
      </div>
		</div>
	)
}
import { useEffect, useState } from 'react';
import Base from '../../utils/base';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../../components/header';

import HomeList from '../../components/homeList';


export default function HomeIndex(){
  var base = new Base()

  const [user_data, set_user_data] = useState({name : '', email : '', phone : '', image : {image_display : base.img_no_profile}, current_academic_year : {id : '', name : ''}, arr_schedule : {}})

  const [to_do_list_arr, set_to_do_list_arr] = useState([])

  const [schedule_lesson_day_arr, set_schedule_lesson_day_arr] = useState([])
  const [schedule_lesson_time_arr, set_schedule_lesson_time_arr] = useState([])
  const [schedule_arr, set_schedule_arr] = useState([])

  const [schedule_page, set_schedule_page] = useState('1')

  useEffect(async ()=>{
    var check_user = await base.checkAuth()
		if(check_user != null && check_user.user_data != null)
    	set_user_data(check_user.user_data)
		else{
			localStorage.removeItem('token')
			window.location.href = '/auth/login'
		}
  }, [])

  useEffect(async ()=>{
    if(user_data.id !== ''){
      get_todo()
      get_schedule()
    }
  }, [user_data])

  useEffect(async ()=>{
		set_schedule_arr([])
		get_schedule()
	}, [schedule_page])

    async function get_todo(){
      var url = '/announcement?type=for_student_teacher'
      var response = await base.request(url)
      if(response != null){
        if(response.status == 'success'){
          var data = response.data.data
          set_to_do_list_arr(data)
        }
      }
    }

    async function get_schedule(){
        var counter_time = ''
        var limit_time = ''
        counter_time = base.moment('08:00', 'HH:mm')
        limit_time = base.moment('13:45', 'HH:mm')
        var arr_time = []

        while(counter_time.isSameOrBefore(limit_time)){
          arr_time.push({
            id: counter_time.format('HH:mm'),
            name: counter_time.format('HH:mm'),
            name_sm: counter_time.format('HH:mm'),
            counter : counter_time.add(15, 'm').format('HH:mm'),
          })
        }

        set_schedule_lesson_time_arr(arr_time)

        var day_arr = []

        for(var x=1;x<=5;x++){
            var weekStartDate = base.moment().day(x)
            day_arr.push({id : x, title : base.moment(weekStartDate).format('dddd')})
        }
        await set_schedule_lesson_day_arr(day_arr)

        var arr_schedule = []
        var data = user_data.arr_schedule
        set_schedule_arr(data)
    }

    return(
      <div className=''>

        <div className=''>
          <Header title={'Dashboard'} user_data={user_data} />
        </div>

        <div className=' pt-4 mt-3'>
          <HomeList
            data_arr={to_do_list_arr}
            title={'Annoucement'}
            icon={'fas fa-folder'}/>
        </div>

        <div className='card p-0 rounded border-0 mt-3'>
          <div className='card-body p-0 pb-3'>

            <div className="table-responsive rounded">
              <table className="table table-fixed-lg">
                <tbody className='bg-white'>
                  <tr className='' style={{backgroundColor : '#F9FAFB', borderBottom : '1px solid #E5E7EB'}}>
                    <th className='border-0 '></th>
                    {
                      schedule_lesson_day_arr.map((day_data, day_index) => <th className='text-center border-0 schedule_day' style={{color : '#8A92A6'}} key={day_index}>{day_data.title}</th>)
                    }
                  </tr>

                  {
                    schedule_lesson_time_arr.map((data_time, index_time)=>(
                      <tr key={index_time}>
                        <td className='border-0 px-0 pt-1 pb-0 align-middle td-fit-content'>
                          <p className='m-0 p-0 px-1 px-lg-3 schedule_time'>{data_time.name + ' - ' + data_time.counter}</p>
                        </td>

                        {
                          schedule_arr != null && (
														schedule_arr[schedule_lesson_day_arr[0].id] != null && schedule_arr[schedule_lesson_day_arr[0].id][data_time.name] != null && schedule_arr[schedule_lesson_day_arr[0].id][data_time.name].type === 'event' ?
															<td className='border-0 px-0 pt-1 pb-0 align-middle' colSpan={5}>
																<div className='h-100 px-2'>
																	<div className='m-0 p-2 px-3 rounded' style={{backgroundColor : '#EBEFE2'}}>
																		<p className='m-0 schedule_subject text-center' style={{color : '#B6C0A0', fontFamily : 'InterBold', lineHeight : '1rem'}}>
																			{schedule_arr[schedule_lesson_day_arr[0].id][data_time.name].type === 'schedule' ? schedule_arr[schedule_lesson_day_arr[0].id][data_time.name].subject.name : schedule_arr[schedule_lesson_day_arr[0].id][data_time.name].name}
																		</p>
																	</div>
																</div>
															</td>
														:
															schedule_lesson_day_arr.map((data_day, index_day)=>{
																var schedule = {}
																if(
																	schedule_arr[data_day.id] != null &&
																	schedule_arr[data_day.id][data_time.name] != null &&
																	schedule_arr[data_day.id][data_time.name].start_time != null
																)
																	schedule = schedule_arr[data_day.id][data_time.name]

																return (
																	<td className='border-0 px-0 pt-1 pb-0 align-middle' key={index_day}>
																		{
																			schedule.id != null &&
																			<div className='h-100 px-2'>
																				<div className='p-2 d-flex align-items-center'
																					style={{borderLeft : '.5rem solid #DE496E', backgroundColor : '#DE496E33', borderRadius : '.5rem'}}
																					data-toggle="tooltip"
																					data-placement="top"
																					title={
																						schedule.type === 'schedule' ?
																						schedule.subject.name + ' - ' + schedule.class_model.grade.name + ' - ' + schedule.class_model.name :
																						schedule.name
																					}>
																					<div>
																						<p className='m-0 schedule_subject h-100' style={{color : '#6B7280', lineHeight : '1rem',}}>
																							{
																								schedule.type === 'schedule' ?
																								schedule.subject.name + ' - ' + schedule.class_model.grade.name + ' - ' + schedule.class_model.name :
																								schedule.name
																							}
																						</p>
																					</div>
																				</div>
																			</div>
																		}
																	</td>
																)
															})
													)
                        }
                      </tr>
                    ))
                  }

                </tbody>
              </table>
            </div>

          </div>
        </div>

      </div>
    )
}
import { useEffect, useState } from 'react';
import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';

export default function ModalEditScore({grade_skill_selected, grade_book_selected, changeNotes, onSubmitNotes, onSubmitScore, onSubmitCompleted }){
	var base = new Base()

	const [comment, set_comment] = useState('')
	const [score, set_score] = useState('')

	useEffect(() => {
		if(grade_book_selected.id != null){
			const summernote = base.$('#summernote')
			summernote.summernote({
				height : 200,
				callbacks: {
					onChange: function(contents, $editable) {
						set_comment(contents)
					},
				},
			})

			summernote.summernote('code', grade_book_selected.comment)
			// set_comment(grade_book_selected.comment)
		}
	}, [grade_book_selected, ])

	useEffect(() => {
		if(grade_skill_selected.id != null)
			set_score(grade_skill_selected.score)
	}, [grade_skill_selected, ])

	async function submitNotesNew(){
		if(comment != ''){
			grade_book_selected.notes = comment

			var response = await base.request('/grade/skill/notes', 'put', grade_book_selected)
			if(response != null){
				if(response.status == 'success'){
					onSubmitCompleted()
				}
			}
		}
	}

	function onSubmit(){
		if(grade_skill_selected.project_skill != null)
			submitGradeSkillNew()
		else if(grade_skill_selected.assignment != null)
			submitGradeScoreNew()
	}

	async function submitGradeSkillNew(){
		if(score != ''){
			var data = JSON.parse(JSON.stringify(grade_skill_selected))
			data.score = score

			var response = await base.request('/grade/skill', 'put', data)
			if(response != null){
				if(response.status == 'success'){
					onSubmitCompleted()
				}
			}
		}
	}

	async function submitGradeScoreNew(){
		if(score != ''){
			var data = JSON.parse(JSON.stringify(grade_skill_selected))
			data.score = score
			data.status = 'done'

			var response = await base.request('/assessment/assignment', 'put', data)
			if(response != null){
				if(response.status == 'success'){
					onSubmitCompleted()
				}
			}
		}
	}

	function onScoreChanged(val){
		if(grade_skill_selected.project_skill != null)
			set_score(parseInt(val) <= 5 && parseInt(val) >= 1 ? val : '')
		else if(grade_skill_selected.assignment != null)
			set_score(parseInt(val) <= 100 && parseInt(val) >= 1 ? val : '')
	}

	return(
		<>
			{
				grade_skill_selected.id != null &&
				<div className="modal fade" id="modalEditScore" data-backdrop="static" tabIndex="-1" aria-labelledby="modalEditScoreLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg">
						<div className="modal-content rounded border-0 shadow-sm">
							<div className="modal-body p-0">

								<div className={'row m-0'}>
									<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
									<div className='col-12 p-3 pt-4 pb-5'>
										<div className='mb-3'>
											<h5 className='m-0'>
												<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
												Edit Score
											</h5>
											{
												grade_skill_selected.project_skill != null ?
												<h6 className='m-0' style={{color : 'black'}}>
													{grade_skill_selected.project_skill.skill.skill_category.name} - {grade_skill_selected.project_skill.skill.name}
												</h6>
												:
												<h6 className='m-0' style={{color : 'black'}}>
													{grade_skill_selected.assignment.name}
												</h6>
											}

										</div>

										<div className='mb-3'>
											<input type='text'
												className="form-control form-control-lg rounded"
												style={{ backgroundColor : 'white', }}
												value={score}
												onChange={(e) => onScoreChanged(e.target.value)} />
										</div>

										<div className='mt-4 text-right'>
											<button className='btn btn-outline-primary rounded px-5' data-dismiss="modal">Cancel</button>
											<button className='btn btn-primary rounded px-5 ml-2' onClick={()=>onSubmit()}>Submit</button>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			}

			{
				grade_book_selected.id != null &&
				<div className="modal fade" id="modalEditNotes" data-backdrop="static" tabIndex="-1" aria-labelledby="modalEditNotesLabel" aria-hidden="true">
					<div className="modal-dialog modal-lg">
						<div className="modal-content rounded border-0 shadow-sm">
							<div className="modal-body p-0">
								<div className={'row m-0'}>

									<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
									<div className='col-12 p-3 pt-4 pb-5'>
										<div className='mb-3'>
											<h5 className='m-0'>
												<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
												Edit Notes
											</h5>
										</div>

										<div className='mb-3'>
											{/* <textarea className="form-control rounded mt-2" id='summernote' rows={3} onChange={(e)=>changeNotes(e.target.value)} value={grade_book_selected.comment} style={{resize : 'none', border : '1px solid #EAEAEA'}} placeholder=""></textarea> */}
											{/* <div id='summernote' dangerouslySetInnerHTML={{__html: grade_book_selected.comment}}></div> */}
											<textarea style={{ height: '200px', }} id='summernote' className='w-100' onChange={(e)=>set_comment(e.target.value)} value={comment}></textarea>
										</div>

										<div className='mt-4 text-right'>
											<button className='btn btn-outline-primary rounded px-5' data-dismiss="modal" >Cancel</button>
											<button className='btn btn-primary rounded px-5 ml-2' onClick={() => submitNotesNew()}>Submit</button>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}
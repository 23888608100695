import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import LoadingData from '../../../components/loading';

export default function HomeroomReportSkill({ skill_student_arr, }){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [arr_subject, set_arr_subject] = useState([])
	const [arr_grade_skill, set_arr_grade_skill] = useState([])
	const [arr_grade_book, set_arr_grade_book] = useState([])
	const [legend_arr, set_legend_arr] = useState([])
	const [student_selected, set_student_selected] = useState(null)
	const [student_selected_data, set_student_selected_data] = useState({})
	const [is_commit, set_is_commit] = useState(false)
	const [is_loading, set_is_loading] = useState(null)

	useEffect(() => {
		get_legend()
	}, [])

	useEffect(() => {
		if(is_commit){
			for(let student of skill_student_arr){
				if(student.id == student_selected){
					set_student_selected_data(student)
					break
				}
			}
		}
	}, [student_selected, skill_student_arr, is_commit, ])

	useEffect(() => {

		if(student_selected_data.id != null){
			set_arr_subject([])
			get_data()
		}
	}, [student_selected_data, ])

	useEffect(async () => {
		if(skill_student_arr.length > 0){
			set_is_commit(true)
			set_student_selected(skill_student_arr[0].id)
		}
	}, [skill_student_arr])

	async function get_data(){
		set_is_loading(true)
		var response = await base.request(
			'/class/grade-skill?id=' + query.get('id') +
			'&user_id=' + student_selected_data.id
		)

		set_is_commit(false)
		set_is_loading(false)
		if(response != null){
			if(response.status == 'success'){
				for(var subject of response.data.arr_subject)
					subject.is_show = false
				response.data.arr_subject[0].is_show = true
				set_arr_subject(response.data.arr_subject)
				set_arr_grade_skill(response.data.arr_grade_skill)
				set_arr_grade_book(response.data.arr_grade_book_skill)
			}
		}
	}

	async function get_legend(){
		var url = '/assessment/range'

		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				var data = response.data.data
				set_legend_arr(data)
			}
		}
	}

	function onSubjectClicked(index){
		var arr = JSON.parse(JSON.stringify(arr_subject))
		for(var temp of arr)
			temp.is_show = false
		arr[index].is_show = true
		set_arr_subject(arr)
	}

	return(
		<div className=''>
			<div className='row'>
				<div className='col-12 col-lg-6'>
					<div className="card rounded shadow-sm h-100">
						<div className={"card-body p-4"}>
							<div className='row'>
								<div className='col-12'>
									<label>Student Name</label>
								</div>
								<div className='col'>
									<SelectOption
										data_arr={skill_student_arr}
										selected={student_selected}
										title={'Student'}
										changeInput={(value)=>set_student_selected(value)}/>
								</div>
								<div className='col-auto d-flex align-items-center justify-content-end'>
									<button className='btn btn-primary rounded' onClick={() => set_is_commit(true)}>Filter</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-12 col-lg-6 mt-3 mt-lg-0'>
					<div className="card rounded shadow-sm h-100">
						<div className={"card-body p-0"}>
							<div className='row m-0'>
								<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
								<div className='col-12 p-4'>
									{ legend_arr.map((data, index) => <p className='m-0' key={index}>{data.legend} : {data.name}</p>) }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{
				is_loading ?
				<LoadingData/>
				:
				<div className="card rounded shadow-sm mt-5">
					<div className={"card-body p-0"}>

						<div className={'row m-0'}>
							<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
							<div className='col-12 p-3 pt-4'>
								<div className='mb-3 pr-3'>
									<h5 className='m-0'>
										<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
										{student_selected_data.id == null ? 'Nama Siswa' : student_selected_data.name}
									</h5>
								</div>

								<div className='m-0 mt-3'>
									{
										arr_subject.map((subject, index)=>
											<div className="table-responsive">
												<table className="table table-borderless">
													<thead style={{backgroundColor : '#EBEFE2'}}>
														<tr style={{cursor : 'pointer'}} onClick={()=>onSubjectClicked(index)}>
															<th style={{width : '6rem'}}>
																{subject.name}
																<i className={"ml-3 fas fa-chevron-" + (subject.is_show ? 'up' : 'down')}/>
															</th>
															{
																subject.is_show &&
																subject.arr_project.map((project, index_assignment) => <th className='text-center' key={index_assignment} style={{width : '6rem'}}>{project.name}</th>)
															}
														</tr>
													</thead>

													{
														subject.is_show &&
														<tbody>
															{
																subject.arr_skill_category.map((category, index_category)=>
																	<>
																		<tr key={index_category}>
																			<td colSpan={subject.arr_project.length + 1} style={{backgroundColor : '#F3F4F6'}}>
																				<p className='m-0'>{category.name}</p>
																			</td>
																		</tr>

																		{
																			category.arr_skill.map((skill, index_list)=>
																				skill.skill_category_id === category.id &&
																				<tr key={index_list}>
																					<td className='text-primary pl-4'>{skill.name}</td>

																					{
																						subject.arr_project.map((project, index_assignment)=>
																							<td className='text-center' style={{width : '6rem'}} key={index_assignment}>
																								{
																									arr_grade_skill[subject.id] != null &&
																									arr_grade_skill[subject.id][category.id] != null &&
																									arr_grade_skill[subject.id][category.id][skill.id] != null &&
																									arr_grade_skill[subject.id][category.id][skill.id][project.id] &&
																									<p className={"m-0"}>{arr_grade_skill[subject.id][category.id][skill.id][project.id].score}</p>
																								}
																							</td>
																						)
																					}
																				</tr>
																			)
																		}
																	</>
																)
															}

															<tr style={{backgroundColor : '#EBEFE2'}}>
																<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Average</td>
																{
																	subject.arr_project.map((project, index_assignment)=>
																		<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																			{
																				arr_grade_book[subject.id] != null &&
																				arr_grade_book[subject.id][project.id] != null &&
																				<p className={"m-0"}>
																					{arr_grade_book[subject.id][project.id].average != null ? parseFloat(arr_grade_book[subject.id][project.id].average).toFixed(2) : 0}
																				</p>
																			}
																		</td>
																	)
																}
															</tr>

															<tr style={{backgroundColor : '#EBEFE2'}}>
																<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Score</td>
																{
																	subject.arr_project.map((project, index_assignment)=>
																		<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																			{
																				arr_grade_book[subject.id] != null &&
																				arr_grade_book[subject.id][project.id] != null &&
																				<p className={"m-0"}>
																					{arr_grade_book[subject.id][project.id].total_score != null ? parseFloat(arr_grade_book[subject.id][project.id].total_score).toFixed(2) : 0}
																				</p>
																			}
																		</td>
																	)
																}
															</tr>

															<tr>
																<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Legend of Mark</td>
																{
																	subject.arr_project.map((project, index_assignment)=>
																		<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																			{
																				arr_grade_book[project.id] != null &&
																				arr_grade_book[project.id][project.id] != null &&
																				arr_grade_book[subject.id][project.id].grade_book != null &&
																				<p className={"m-0"}>
																					{arr_grade_book[subject.id][project.id].grade_book.assessment_range.legend}
																				</p>
																			}
																		</td>
																	)
																}
															</tr>
															<tr>
																<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Teacher Notes</td>
																{
																	subject.arr_project.map((project, index_assignment)=>
																		<td className='' style={{width : '6rem'}} key={index_assignment}>
																			{
																				arr_grade_book[subject.id] != null &&
																				arr_grade_book[subject.id][project.id] != null &&
																				<div className='teacher_comment' dangerouslySetInnerHTML={{
																					__html: arr_grade_book[subject.id][project.id].grade_book != null ? arr_grade_book[subject.id][project.id].grade_book.comment : '-',
																				}}></div>
																			}
																		</td>
																	)
																}
															</tr>

														</tbody>
													}
												</table>
											</div>
										)
									}
								</div>
							</div>
						</div>

					</div>
				</div>
			}

		</div>
	)
}
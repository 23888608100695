import { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Base from '../../../utils/base';
import Header from '../../../components/header';
import CardSubject from '../../../components/cardSubject';
import SelectOption from '../../../components/selectOption';
import LoadingData from '../../../components/loading';

import ModalSubmit from '../checkAssignment/modalSubmit';
import ModalEditScore from './modalEditScore';


export default function ReportSkill({ term_arr, user_data, editSkillScore, editNotes, }){
	var base = new Base()
	let query = useQuery()

	const [is_loading_data, set_is_loading_data] = useState(false)
	const [is_commit, set_is_commit] = useState(false)
	const [student_arr, set_student_arr] = useState([])
	const [skill_assignment, set_skill_assignment] = useState([])
	const [skill_list_arr, set_skill_list_arr] = useState([])
	const [skill_ctg_arr, set_skill_ctg_arr] = useState([])
	const [skill_grade_arr, set_skill_grade_arr] = useState([])
	const [skill_grade_book_arr, set_skill_grade_book_arr] = useState([])
	const [legend_arr, set_legend_arr] = useState([])
	const [term_selected, set_term_selected] = useState('')
	const [skill_student_selected, set_skill_student_selected] = useState('')
	const [skill_student_selected_data, set_skill_student_selected_data] = useState({})
	const [grade_skill_selected, set_grade_skill_selected] = useState({})
	const [grade_book_selected, set_grade_book_selected] = useState({})

	useEffect(() => {
		get_legend()
	}, [])

	useEffect(() => {
		if(term_selected != '')
			get_student()
	}, [term_selected, ])

	useEffect(() => {
		if(grade_skill_selected.id != null)
			base.$('#modalEditScore').modal('show')
	}, [grade_skill_selected, ])

	useEffect(() => {
		if(grade_book_selected.id != null)
			base.$('#modalEditNotes').modal('show')
	}, [grade_book_selected, ])

	useEffect(() => {
		if(term_selected != '' && skill_student_selected_data.id != null){
			var url = '/grade-book/detail?subject_id=' + query.get('subject_id') +
				'&grade_id=' + query.get('grade_id') +
				'&tab=report_card_skill' +
				'&student_id=' + skill_student_selected

			if(term_selected != '')
				url += '&term_id=' + term_selected

			window.history.pushState({}, null, url)

			get_data()
		}
	}, [term_selected, skill_student_selected_data, ])

	useEffect(() => {
		if(is_commit){
			set_is_commit(false)

			for(let student of student_arr){
				if(student.id == skill_student_selected){
					set_skill_student_selected_data(student)
					break
				}
			}
		}
	}, [is_commit, ])

	useEffect(async () => {
		if(term_arr.length > 0)
			set_term_selected(user_data.current_term.id)
	}, [term_arr, user_data,])

	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	async function get_student(){
		var response = await base.request(
			'/grade/book/report-card?subject_id=' + query.get('subject_id') +
			'&grade_id=' + query.get('grade_id') +
			'&term_id=' + term_selected
		)

		if(response != null){
			if(response.status == 'success'){
				var data = response.data

				var class_student = data.arr_class_student
				var arr_student = []
				for(var x in class_student)
					arr_student.push(class_student[x].user)

				set_student_arr(arr_student)
			}
		}
	}

	async function get_data(){
		set_is_loading_data(true)
		var response = await base.request(
			'/grade/book/report-card/skill?subject_id=' + query.get('subject_id') +
			'&grade_id=' + query.get('grade_id') +
			'&term_id=' + term_selected +
			'&user_id=' + skill_student_selected_data.id
		)
		setTimeout(() => {
			set_is_loading_data(false)
		}, 1000);

		if(response != null){
			if(response.status == 'success'){
				set_skill_assignment(response.data.arr_assignment.data)
				set_skill_list_arr(response.data.arr_skill)
				set_skill_ctg_arr(response.data.arr_skill_category)
				set_skill_grade_arr(response.data.arr_grade_skill)
				set_skill_grade_book_arr(response.data.arr_grade_book_skill)
			}
		}
	}

	async function get_legend(){
		var url = '/assessment/range'

		var response = await base.request(url)
		if(response != null){
			if(response.status == 'success'){
				set_legend_arr(response.data.data)
			}
		}
	}

	function onSubmitCompleted(){
		base.$('#modalEditScore').modal('hide')
		base.$('#modalEditNotes').modal('hide')
		get_data()
	}

// 	async function editNotes(index_assignment){
// 		set_edit_type('notes')
// 		set_grade_book_selected(skill_grade_book_arr[skill_assignment[index_assignment].id].grade_book)
// 	}

	return(
		<div className=''>
			<div className='row'>
				<div className='col-12 col-lg-6'>
					<div className="card rounded shadow-sm h-100">
						<div className={"card-body p-4"}>
							<div className='row'>
								<div className='col-12'>
									<label>Student Name</label>
								</div>

								<div className='col'>
									<SelectOption
										data_arr={student_arr}
										selected={skill_student_selected}
										title={'Student'}
										changeInput={(value) => set_skill_student_selected(value)}/>
								</div>

								<div className='col-auto d-flex align-items-center justify-content-end'>
									<button className='btn btn-primary rounded' onClick={() => set_is_commit(true)}>Filter</button>
								</div>

								{
									skill_student_selected_data.id == null &&
									<div className='col-12 mt-3'>
										<label>Please Choose Student First</label>
									</div>
								}

							</div>
						</div>
					</div>
				</div>

				<div className='col-12 col-lg-6 mt-3 mt-lg-0'>
					<div className="card rounded shadow-sm h-100">
						<div className={"card-body p-0"}>
							<div className='row m-0'>

								<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
								<div className='col-12 p-4'>
									{ legend_arr.map((data, index) => <p className='m-0' key={index}>{data.legend} : {data.name}</p>) }
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{
				skill_student_selected_data.id != null && term_selected != '' &&
				<div className='mt-5'>
					<div className="card rounded shadow-sm">
						<div className={"card-body p-0"}>
							<div className={'row m-0'}>

								<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
								<div className='col-12 p-3 pt-4'>
									<div className='mb-3 pr-3'>
										<div className='row m-0'>
											<div className='col-12 col-lg'>
												<h5 className='m-0'>
													<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
													{skill_student_selected_data.id == null ? 'Nama Siswa' : skill_student_selected_data.name}
												</h5>
											</div>
											<div className='col-12 col-lg-auto'>
												<SelectOption
													data_arr={term_arr}
													selected={term_selected}
													title={'Term'}
													changeInput={(value) => set_term_selected(value)}/>
											</div>
										</div>
									</div>

									{
										is_loading_data ?
										<div className='row mb-3'>
											<LoadingData />
										</div>
										:
											<div className="table-responsive mt-3">
												<table className="table table-borderless">
													<thead style={{backgroundColor : '#EBEFE2'}}>
														<tr>
															<th style={{width : '6rem'}}></th>
															{ skill_assignment.map((data_assignment, index_assignment) => <th className='text-center' key={index_assignment} style={{width : '6rem'}}>{data_assignment.name}</th>) }
														</tr>
													</thead>

													<tbody>

														{
															skill_ctg_arr.map((data_category, index_category)=>
																<>
																	<tr key={index_category}>
																		<td colSpan={skill_assignment.length + 1} style={{backgroundColor : '#F3F4F6'}}>
																			<p className='m-0'>{data_category.name}</p>
																		</td>
																	</tr>

																	{
																		skill_list_arr.map((data_list, index_list)=>
																			data_list.skill_category_id === data_category.id &&
																			<tr key={index_list}>
																				<td className='text-primary pl-4'>{data_list.name}</td>

																				{
																					skill_assignment.map((data_assignment, index_assignment)=>
																						<td className='text-center' style={{width : '6rem'}} key={index_assignment}>
																							{
																								skill_grade_arr[data_category.id] != null &&
																								skill_grade_arr[data_category.id][data_list.id] != null &&
																								skill_grade_arr[data_category.id][data_list.id][data_assignment.id] != null &&
																								<p className={"m-0"}>
																									{skill_grade_arr[data_category.id][data_list.id][data_assignment.id].score}
																									<i className="bi bi-pencil-fill ml-2"
																										style={{cursor : 'pointer', fontSize : '.75rem'}}
																										onClick={() => set_grade_skill_selected(skill_grade_arr[data_category.id][data_list.id][data_assignment.id])}/>
																								</p>
																							}
																						</td>
																					)
																				}
																			</tr>
																		)
																	}
																</>
															)
														}

														<tr style={{backgroundColor : '#EBEFE2'}}>
															<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Average</td>
															{
																skill_assignment.map((data_assignment, index_assignment)=>
																	<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																		{
																			skill_grade_book_arr[data_assignment.id] != null &&
																			<p className={"m-0"}>{(skill_grade_book_arr[data_assignment.id].average == null ? 0 : parseFloat(skill_grade_book_arr[data_assignment.id].average).toFixed(2))}</p>
																		}
																	</td>
																)
															}
														</tr>

														<tr style={{backgroundColor : '#EBEFE2'}}>
															<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Score</td>
															{
																skill_assignment.map((data_assignment, index_assignment)=>
																	<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																		{
																			skill_grade_book_arr[data_assignment.id] != null &&
																			<p className={"m-0"}>{skill_grade_book_arr[data_assignment.id].total_score}</p>
																		}
																	</td>
																)
															}
														</tr>

														<tr>
															<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Legend of Mark</td>
															{
																skill_assignment.map((data_assignment, index_assignment)=>
																	<td className='text-center ' style={{width : '6rem'}} key={index_assignment}>
																		{
																			skill_grade_book_arr[data_assignment.id] != null && skill_grade_book_arr[data_assignment.id].grade_book != null &&
																			<p className={"m-0"}>{skill_grade_book_arr[data_assignment.id].grade_book.assessment_range.legend}</p>
																		}
																	</td>
																)
															}
														</tr>

														<tr>
															<td className='align-middle text-uppercase' style={{color : '#8A92A6'}}>Teacher Notes</td>
															{
																skill_assignment.map((data_assignment, index_assignment)=>
																	<td className='' style={{width : '6rem'}} key={index_assignment}>
																		{
																			skill_grade_book_arr[data_assignment.id] != null &&
																			<div>
																				<div className='teacher_comment' dangerouslySetInnerHTML={{__html: (skill_grade_book_arr[data_assignment.id].grade_book != null ? skill_grade_book_arr[data_assignment.id].grade_book.comment : '-')}}></div>

																				<i className="bi bi-pencil-fill" style={{cursor : 'pointer', fontSize : '.75rem'}} onClick={() => set_grade_book_selected(skill_grade_book_arr[data_assignment.id].grade_book)}/>
																			</div>
																		}
																	</td>
																)
															}
														</tr>

													</tbody>
												</table>
											</div>
									}
								</div>

							</div>
						</div>
					</div>
				</div>
			}

			<ModalEditScore
				grade_skill_selected={grade_skill_selected}
				grade_book_selected={grade_book_selected}
				onSubmitNotes={() => set_grade_book_selected({})}
				onSubmitScore={() => set_grade_skill_selected({})}
				onSubmitCompleted={() => onSubmitCompleted()}/>

		</div>
	)
}
import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import Base from '../../../utils/base';
import Header from '../../../components/header';
import CardSubject from '../../../components/cardSubject';
import SelectOption from '../../../components/selectOption';
import ModalEditScore from './modalEditScore';


export default function ReportGrade({ term_arr, editScore, user_data, }){
	var base = new Base()
	let query = useQuery()

	const [is_loading_data, set_is_loading_data] = useState(true)
	const [class_student, set_class_student] = useState([])
	const [assignment_agreement, set_assignment_agreement] = useState([])
	const [grade_book_arr, set_grade_book_arr] = useState([])
	const [student_arr, set_student_arr] = useState([])
	const [term_selected, set_term_selected] = useState('')
	const [grade_skill_selected, set_grade_skill_selected] = useState({id : '', score : '', project_skill : {skill : {name : '', skill_category : {name : ''}}}})
	const [numerical_score, set_numerical_score] = useState('')
	const [activity_assessment_rule_selected, set_activity_assessment_rule_selected] = useState('')
	const [class_student_id, set_class_student_id] = useState('')
	const [is_student_online_selected, set_is_student_online_selected] = useState(false)
	const [edit_score_selected, set_edit_score_selected] = useState({id : '', score : '', comment : '', activity_type : ''})
	const [grade_book_selected, set_grade_book_selected] = useState({id : '', comment : ''})

	useEffect(() => {
		if(term_selected != '')
			get_data()
	}, [term_selected, ])

	useEffect(async () => {
		if(term_arr.length > 0)
			set_term_selected(user_data.current_term.id)
	}, [term_arr, user_data,])

	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	async function get_data(){
		set_is_loading_data(true)
		var response = await base.request(
			'/grade/book/report-card?subject_id=' + query.get('subject_id') +
			'&grade_id=' + query.get('grade_id') +
			'&term_id=' + term_selected
		)
		setTimeout(() => {
			set_is_loading_data(false)
		}, 1000);

		if(response != null){
			if(response.status == 'success'){
				var data = response.data

				set_class_student(response.data.arr_class_student)
				set_assignment_agreement(response.data.arr_assignment_agreement.data)
				set_grade_book_arr(response.data.arr_grade_book_detail)

				var arr_student = []
				for(var student of response.data.arr_class_student)
					arr_student.push(student.user)
				set_student_arr(arr_student)
			}
		}
	}

	function changeGradeSkillScore(value){
		var score = parseInt(value) <= 5 && parseInt(value) >= 1 ? value : ''
		base.update_object(grade_skill_selected, set_grade_skill_selected, score, 'score')
	}

	async function editScore(index, index_agreement){
		var data = grade_book_arr[class_student[index].id][assignment_agreement[index_agreement].id]
		data.activity_type = 'quiz'
		data.assignment = assignment_agreement[index_agreement]

		set_activity_assessment_rule_selected(assignment_agreement[index_agreement].assessment_rule_id)
		set_numerical_score(data.score)

		set_class_student_id(class_student[index].id)

		set_is_student_online_selected(class_student[index].is_online)
		set_edit_score_selected(data)
		set_grade_skill_selected(data)

		base.$('#modalEditScore').modal('show')
	}

	function onSubmitCompleted(){
		get_data()
		base.$('#modalEditScore').modal('hide')
	}

	return(
		<div>
			<div className="card rounded shadow-sm mt-5">
				<div className={"card-body p-0"}>
					<div className={'row m-0'}>

						<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />

						<div className='col-12 p-3 pt-4'>
							<div className='row m-0'>

								<div className='col-12 mb-3 px-0'>
									<div className='row m-0'>
											<div className='col-12 col-lg'>
												<h5 className='m-0'>
													<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
													Report Card Grade
												</h5>
											</div>

											<div className='col-12 col-lg-auto'>
												<SelectOption
													data_arr={term_arr}
													selected={term_selected}
													title={'Term'}
													changeInput={(value)=>set_term_selected(value)}/>
											</div>
									</div>
								</div>

								<div className='col-12 p-0'>
									<div className="table-responsive">
										<table className="table">

											<thead>
												<tr>
													<td className='py-2'>No</td>
													<td className='py-2'>Student Name</td>
													<td className={'text-center py-2'} style={{backgroundColor : '#FFF2CA'}}>Final Score</td>
													{ assignment_agreement.map((data, index) => <td key={index} className={'text-center py-2'}>{data.name}</td>) }
												</tr>
											</thead>

											<tbody>
												{
													class_student.map((data, index)=>
														<tr key={index}>

															<td className='td-fit-content py-2'>{parseInt(index)+1}</td>
															<td className='py-2'>{data.user.name}</td>

															<td className={'text-center align-middle py-2'} style={{backgroundColor : '#FFF2CA'}}>
																{
																	grade_book_arr[data.id] != null && grade_book_arr[data.id]['final_score'] != null &&
																	<p className='m-0'>
																		{grade_book_arr[data.id]['final_score'].average_score != null ? parseFloat(grade_book_arr[data.id]['final_score'].average_score).toFixed(2) : 0}
																	</p>
																}
															</td>

															{
																assignment_agreement.map((data_agreement, index_agreement)=>
																	<td key={index_agreement} className={'text-center align-middle py-2'}>
																		{
																			grade_book_arr[data.id] != null && (
																				grade_book_arr[data.id][data_agreement.id] != null ?
																					<p className='m-0'>
																						{grade_book_arr[data.id][data_agreement.id].score}
																						<i className="bi bi-pencil-fill" style={{cursor : 'pointer', fontSize : '.6rem'}}
																							onClick={()=>editScore(index, index_agreement)}/>
																					</p>
																				:
																					<p className='m-0'>0</p>
																			)
																		}
																	</td>
																)
															}
														</tr>
													)
												}
											</tbody>

										</table>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>
			</div>

			<ModalEditScore
				grade_skill_selected={grade_skill_selected}
				grade_book_selected={grade_book_selected}
				onSubmitCompleted={() => onSubmitCompleted()}/>
		</div>
	)
}
import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import LoadingData from '../../../components/loading';

export default function HomeroomStudentHabitDetail({ habit_student_selected, viewOnGoing, }){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [search, set_search] = useState('')
	const [accomplished_habit_arr, set_accomplished_habit_arr] = useState([])
	const [talents_transaction_arr, set_talents_transaction_arr] = useState([])

	useEffect(() => {
		get_habit_transaction()
	}, [])

	useEffect(() => {
		get_data()
	}, [search, ])

	async function get_data(){
		var response = await base.request('/habit/tracker?class_id=' + query.get('id') +
			'&type=accomplished' +
			'&search=' + search
		)

		if(response != null){
			if(response.status == 'success'){
				set_accomplished_habit_arr(response.data.data)
			}
		}
	}

	async function get_habit_transaction(){
		var response = await base.request('/talent/transaction?class_student_id=' + habit_student_selected.class_student_id)

		if(response != null){
			if(response.status == 'success'){
				set_talents_transaction_arr(response.data.data)
			}
		}
	}

	return(
		<div>
      <div className='row'>
        <div className='col-12 col-lg-auto'>
          <div className="card rounded shadow-sm">
            <div className={"card-body p-3"}>
              <div className='row'>
                <div className='col-auto'>
                  <img src={habit_student_selected.image_display} style={{height : '4rem', width : '4rem', aspectRatio : 1, borderRadius : '4rem'}} />
                </div>

                <div className='col-auto pl-0 d-flex align-items-center'>
                  <h6 className='m-0 text-secondary' style={{fontFamily : 'InterBold'}}>{habit_student_selected.name}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg mt-3 mt-lg-0'>
          <div className="card rounded shadow-sm h-100">
            <div className={"card-body p-3 d-flex flex-column align-items-center"}>
              <h3 className='m-0 text-secondary' style={{fontFamily : 'InterBold'}}>{habit_student_selected.talent_balance}</h3>
              <p className='m-0'>Total Talents</p>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg mt-3 mt-lg-0'>
          <div className="card rounded shadow-sm h-100">
            <div className={"card-body p-3 d-flex flex-column align-items-center"}>
              <h3 className='m-0 text-secondary' style={{fontFamily : 'InterBold'}}>Rp. {parseFloat(habit_student_selected.talent_rate.rate).toLocaleString(base.currencyFormat)}</h3>
              <p className='m-0'>Kurs per Talent</p>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg-auto mt-3 mt-lg-0'>
          <div className="card rounded shadow-sm h-100">
            <div className={"card-body p-3 d-flex flex-column align-items-center"}>
              <h5 className='m-0 text-secondary' style={{fontFamily : 'InterBold'}}>On Going Habit Challenge</h5>
              <button className='btn btn-sm btn-primary rounded px-3 mt-2' onClick={viewOnGoing}>View More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="card rounded shadow-sm mt-5">
        <div className={"card-body p-0"}>

          <div className={'row m-0'}>
            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
            <div className='col-12 p-3 pt-4'>
              <div className='mb-3 pr-3'>
              	<h5 className='m-0'>
									<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
									History Habit Accomplished
								</h5>
              </div>

              <div className="input-group border rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white border-0 bg-transparent pr-0" id="basic-addon1">
										<i className="bi bi-search"/>
									</span>
                </div>

                <input type="text" className="form-control border-0 bg-transparent" placeholder="Search" aria-describedby="basic-addon1" value={search} onChange={(e) => set_search(e.target.value)} />
              </div>

              <div className="table-responsive mt-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Category</th>
                      <th>Habit</th>
                      <th>Talents Received</th>
                      <th>Confirmed Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      accomplished_habit_arr.map((data, index)=>
                        <tr key={index}>
                          <td className='align-middle td-fit-content'>{base.moment(data.start_date).format('DD/MM') + ' - ' + base.moment(data.end_date).format('DD/MM')}</td>
                          <td className='align-middle'>{data.category_habit.name}</td>
                          <td className='align-middle'>{data.name}</td>
                          <td className='align-middle'>{data.talent_earned}</td>
                          <td className='align-middle'>{(data.confirm_at != null ? base.moment(data.confirm_at).format('DD/MM HH:mm') : '-')}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="card rounded shadow-sm mt-5">
        <div className={"card-body p-0"}>

          <div className={'row m-0'}>
            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
            <div className='col-12 p-3 pt-4'>
              <div className='mb-3 pr-3'>
                <h5 className='m-0'>
									<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
									In Out Talents
								</h5>
              </div>

              <div className="table-responsive mt-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date Time</th>
                      <th>Talents In</th>
                      <th>Talents Out</th>
                      <th>Status</th>
                      <th>Rupiah</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      talents_transaction_arr.map((data, index)=>
                        <tr key={index}>
                          <td className='align-middle'>{base.moment(data.created_at).format('DD/MM/YY - HH:mm')}</td>
                          <td className='align-middle text-secondary'>{data.type === 'in' ? '+' + data.amount : ''}</td>
                          <td className='align-middle text-danger'>{data.type === 'out' ? '-' + data.amount : ''}</td>
                          <td className='align-middle'>{(data.is_approve ? 'DONE' : 'PENDING')}</td>
                          <td className='align-middle'>Rp. {parseFloat(data.amount_convert).toLocaleString(base.currencyFormat)}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
		</div>
	)
}
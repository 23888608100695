import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import 'summernote'

export default function TeacherComment(props){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

  const [semester_selected, set_semester_selected] = useState('')
  const [data_selected, set_data_selected] = useState({id : ''})
  const [student_selected, set_student_selected] = useState({id : '', name : ''})
  const [input_notes, set_input_notes] = useState('')
	const [search, set_search] = useState('')
	const [student_arr, set_student_arr] = useState([])
	const [timeout, set_timeout] = useState(null)
	const [index, set_index] = useState(-1)
	const [index_grade_book, set_index_grade_book] = useState(-1)

  useEffect(()=>{
    base.$('#teacherCommentModal').on('show.bs.modal', function (event) {
      const summernote = base.$('#summernote')
      summernote.summernote({
        height : 200,
        callbacks: {
          onChange: function(contents, $editable) {
            set_input_notes(contents)
          }
        }
      })
    })

    base.$('#teacherCommentModal').on('hidden.bs.modal', function (event) {
      set_input_notes('')
      set_student_selected({ id : '', })
      set_data_selected({ id : '', })
      base.$('#summernote').summernote('destroy');
    })
  }, [])

  useEffect(()=>{
    if(data_selected.id !== '' && student_selected.id !== '')
      base.$('#teacherCommentModal').modal('show')
  }, [data_selected, student_selected])

	useEffect(()=>{
		if(timeout != null)
			clearTimeout(timeout)

		set_timeout(
			setTimeout(() => {
				get_data()
			}, 1500)
		)
	}, [search, ])

	async function get_data(){
		var response = await base.request('/class/homeroom?id=' + query.get('id') + '&student_search=' + search)

		if(response != null){
			if(response.status == 'success'){
				set_student_arr(response.data != null ? response.data.student : [])
			}
		}
	}

	async function addTeacherComment(index, index_grade_book){
		set_student_selected(student_arr[index])
		set_data_selected(student_arr[index].arr_grade_book[index_grade_book])
		set_semester_selected(index_grade_book === 0 ? 1 : 2)
		set_index(index)
		set_index_grade_book(index_grade_book)

		set_input_notes(student_arr[index].arr_grade_book[index_grade_book].comment)
	}

  async function submitNotes(){
    var url = '/grade/book'
    var data = {
      id : data_selected.id,
      comment : input_notes
    }

    var response = await base.request(url, 'put', data)
    if(response != null){
			if(response.status == 'success'){
				base.$('#teacherCommentModal').modal('hide')

				var arr = JSON.parse(JSON.stringify(student_arr))
				arr[index].arr_grade_book[index_grade_book].comment = input_notes
				set_student_arr(arr)

				set_index(-1)
				set_index_grade_book(-1)
			}
    }
  }

	return(
		<div className=''>
      <div className="card rounded shadow-sm">
        <div className={"card-body p-0"}>

          <div className={'row m-0'}>
            <img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
            <div className='col-12 p-3 pt-4'>
              <div className='mb-3 pr-3'>
                <h5 className='m-0'>
									<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
									Student List
								</h5>
              </div>

              <div className="input-group border rounded">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-white border-0 bg-transparent pr-0" id="basic-addon1">
										<i className="bi bi-search"/>
									</span>
                </div>

                <input type="text"
									className="form-control border-0 bg-transparent"
									placeholder="Search"
									aria-describedby="basic-addon1"
									value={search}
									onChange={(e)=>set_search(e.target.value)} />
              </div>

              <div className="table-responsive mt-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Student Name</th>
                      <th>L/P</th>
                      <th>Semester 1</th>
                      <th>Semester 2</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      student_arr.map((data, index)=>
                        <tr key={index}>
                          <td className='align-middle td-fit-content'>{data.id}</td>
                          <td className='align-middle'>{data.user.name}</td>
                          <td className='align-middle'>{(data.user.gender === 1 ? 'L' : 'P')}</td>
                          {
                            data.arr_grade_book.map((data_grade_book, index_grade_book)=>
                              <td className='align-middle' key={index_grade_book}>
                                <div className={'teacher_comment'} dangerouslySetInnerHTML={{ __html: data_grade_book.comment.length > 500 ? data_grade_book.comment.substring(0, 500) + '...' : data_grade_book.comment, }}></div>

                                <button className='btn btn-primary d-flex justify-content-center align-items-center'
																	style={{height : '2.5rem', width : '2.5rem', borderRadius : '2.5rem'}}
																	onClick={()=>addTeacherComment(index, index_grade_book)}>
                                  <i className="bi bi-pencil-fill"/>
                                </button>
                              </td>
                            )
                          }
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="modal fade" id="teacherCommentModal" tabIndex="-1" aria-labelledby="teacherCommentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded border-0 shadow-sm">
            <div className="modal-body p-0">
              <div className='p-3 pt-4 pb-5'>
                <div className='mb-3'>
                  <h5 className='m-0'>
										<i className="bi bi-chat-square-dots-fill mr-3" style={{color : '#00000066'}}/>
										Teacher's Comment
									</h5>
                </div>

                <div className='mt-3 pb-3'>
                  <div className=''>
                    <p className='m-0'>{student_selected.name}</p>
                  </div>

                  <div className=' mt-3'>
                    <label>Semester {semester_selected}</label>
                    {/* <textarea className='form-control' rows={5} onChange={(e)=>set_input_notes(e.target.value)} value={input_notes}></textarea> */}
										<textarea style={{ height: '200px', }} id='summernote' className='teacher_comment w-100' onChange={(e) => set_input_notes(e.target.value)} value={input_notes}></textarea>
                    {/* <div className='teacher_comment' id='summernote' dangerouslySetInnerHTML={{__html: input_notes}}></div> */}
                  </div>

                  <div className=' mt-4'>
                    <button className='btn btn-primary' onClick={()=>submitNotes()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	)
}
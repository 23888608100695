import { useEffect, useState, useMemo, } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation } from 'react-router-dom';

import LoadingData from '../../../components/loading';
import SelectOption from '../../../components/selectOption';
import Base from '../../../utils/base';
import AttendanceRewardModal from './AttendanceRewardModal';


export default function HomeroomAttendanceReward({ term_arr, user_data, }){
	function useQuery(){
		const {search} = useLocation()
		return useMemo(() => new URLSearchParams(search), [search]);
	}

	var base = new Base()
	let query = useQuery()

	const [term_selected, set_term_selected] = useState('')
	const [term_selected_data, set_term_selected_data] = useState({})
	const [attendance_reward_offset, set_attendance_reward_offset] = useState(0)
	const [attendance_reward_student_selected, set_attendance_reward_student_selected] = useState('')
	const [reward_score, set_reward_score] = useState([])
	const [reward_arr, set_reward_arr] = useState([])
	const [date_arr, set_date_arr] = useState([])
	const [attendance_reward_month, set_attendance_reward_month] = useState('')
	const [attendance_reward_month_moment, set_attendance_reward_month_moment] = useState('')
	const [attendance_submit_date_moment, set_attendance_submit_date_moment] = useState('')
	const [class_student_arr, set_class_student_arr] = useState([])
	const [student_arr, set_student_arr] = useState([])
	const [arr_point, set_arr_point] = useState([])
	const [loading_attendance_reward, set_loading_attendance_reward] = useState(true)
	const [is_enable_left_offset, set_is_enable_left_offset] = useState(true)
	const [is_enable_right_offset, set_is_enable_right_offset] = useState(false)
	const [from_system, set_from_system] = useState(false)
	const [attendance_reward_nav_btn, set_attendance_reward_nav_btn] = useState([
		{icon : 'fas fa-chevron-circle-left', type : 'prev', margin : 'mr-2', color : 'text-secondary'},
		{icon : 'fas fa-chevron-circle-right', type : 'next', margin : 'ml-2', color : 'text-secondary'},
	])

	useEffect(async () => {
		if(term_arr.length > 0)
			set_term_selected(user_data.current_term.id)
	}, [term_arr, user_data, ])

	useEffect(() => {
		if(term_selected != ''){
			for(var temp of term_arr){
				if(temp.id == term_selected){
					set_term_selected_data(temp)
					break
				}
			}
		}
	}, [term_selected, ])

	useEffect(() => {
		if(attendance_reward_month_moment != ''){
			var term_start_date_moment = base.moment(term_selected_data.start_date)
			var term_end_date_moment = base.moment(term_selected_data.end_date)

			set_is_enable_left_offset(term_start_date_moment.isBefore(attendance_reward_month_moment, 'month'))
			set_is_enable_right_offset(term_end_date_moment.isAfter(attendance_reward_month_moment, 'month'))
		}
	}, [attendance_reward_offset, attendance_reward_month_moment, ])

	useEffect(() => {
		if(from_system){
			set_from_system(false)
			return
		}

		if(term_selected_data.id != null)
			get_data()
	}, [term_selected_data, attendance_reward_offset, ])

	async function get_data(){
		set_loading_attendance_reward(true)

		var response = await base.request(
			'/class/attendance-reward?id=' + query.get('id') +
			'&term_id=' + term_selected_data.id +
			'&counter=' + attendance_reward_offset
		)
		set_loading_attendance_reward(false)

		if(response != null){
			if(response.status == 'success'){
				for(var student of response.data.arr_class_student){
					student.is_show = false
					if(attendance_reward_student_selected !== '' && attendance_reward_student_selected === student.user_id)
						student.is_show = true

					student.name = student.user.name
				}

				if(response.data.arr_class_student.length > 0 && attendance_reward_student_selected === '')
					response.data.arr_class_student[0].is_show = true

				for(var reward of response.data.arr_reward)
					reward.score = ''

				var arr_temp = []
				for(var class_student of response.data.arr_class_student)
					arr_temp.push(class_student.user)
				set_student_arr(arr_temp)

				set_reward_score(response.data.arr_reward)
				set_reward_arr(response.data.arr_reward)
				set_date_arr(response.data.arr_lesson_schedule.arr)

				set_attendance_reward_month(response.data.arr_lesson_schedule.arr[0].selected_month)
				set_attendance_reward_month_moment(base.moment(response.data.arr_lesson_schedule.arr[0].month_data, 'YYYY-MM'))
				set_class_student_arr(response.data.arr_class_student)
				set_arr_point(response.data.arr_point)
			}
		}
	}

	function onAddRecord(){
		base.$('#attendanceRewardModal').modal('show')
	}

	function toggleStudent(index){
		var arr = JSON.parse(JSON.stringify(class_student_arr))
		for(var temp of arr)
			temp.is_show = false
		arr[index].is_show = true
		set_class_student_arr(arr)
	}

	function onSubmitAttendanceReward(date){
		var date_moment = base.moment(date, 'YYYY-MM')
		var term_end_date_moment = null
		for(var term of term_arr){
			if(date_moment.isBetween(base.moment(term.start_date), base.moment(term.end_date))){
				term_end_date_moment = base.moment(base.moment(term.end_date).format('YYYY-MM'))
				set_from_system(true)
				set_term_selected(term.id)
				break
			}
		}

		var delta_month = term_end_date_moment.diff(date_moment, 'months')
		set_attendance_reward_offset(delta_month * -1)
	}

	function changeAttendanceOffset(type){
		if(type === 'prev' && is_enable_left_offset)
			set_attendance_reward_offset(attendance_reward_offset - 1)
		else if(type === 'next' && is_enable_right_offset)
			set_attendance_reward_offset(attendance_reward_offset + 1)
	}

	return(
		<div>
			<div className="card rounded shadow-sm">
				<div className={"card-body p-0"}>

					<div className={'row m-0'}>
						<img className='rounded' src={base.img_borderTop_primary} style={{width : '100%', height : '.75rem'}} />
						<div className='col-12 p-3 pt-4'>
							<div className='row m-0 mb-3 pr-3'>
								<div className='col-12 col-lg-auto d-flex align-items-center h-100'>
									<SelectOption
										data_arr={term_arr}
										selected={term_selected}
										title={'Term'}
										changeInput={(value) => set_term_selected(value)}/>
								</div>

								<div className='col-12 col-lg mt-2 mt-lg-0'>
									{
										!loading_attendance_reward &&
										<div className='row h-100'>
											<div className='col-auto p-0 mr-2'>
												<div className='d-flex align-items-center h-100'>
													<h4 className='m-0' style={{cursor : 'pointer'}} onClick={()=>changeAttendanceOffset('prev')}>
														<i className={"fas fa-chevron-circle-left " + (is_enable_left_offset ? 'text-secondary' : 'text-light')}/>
													</h4>
												</div>
											</div>

											<div className='col-auto p-0'>
												<div className='d-flex align-items-center h-100'>
													<h4 className='m-0' style={{cursor : 'pointer'}} onClick={()=>changeAttendanceOffset('next')}>
														<i className={"fas fa-chevron-circle-right " + (is_enable_right_offset ? 'text-secondary' : 'text-light')}/>
													</h4>
												</div>
											</div>
										</div>
									}
								</div>

								<div className='col-12 col-lg mt-2 mt-lg-0 d-flex justify-content-end'>
									<button className='btn btn-primary rounded shadow-sm' onClick={()=>onAddRecord()}>Add Record</button>
								</div>
							</div>

							{
								loading_attendance_reward ?
								<LoadingData />
								:
								<div className="table-responsive">
									<table className="table w-100 table-borderless">
										<thead>
											<tr style={{backgroundColor : '#EBEFE2'}}>
												<td style={{width : '6rem'}}></td>
												<td></td>
												<td className='text-center' colSpan={date_arr.length + 1}>{attendance_reward_month}</td>
											</tr>

											<tr style={{backgroundColor : '#EBEFE2'}}>
												<td style={{width : '6rem'}}></td>
												<td className='text-center'>Total</td>
												{
													date_arr.length > 0 ?
														date_arr.map((data_date, index_date) =>
															<td className='text-center' key={index_date}>{base.moment(data_date.date).format('D')}</td>
														)
													:
													<td></td>
												}
											</tr>
										</thead>

										<tbody>
											{
												class_student_arr.map((data_student, index_student)=>
													<>
														<tr style={{backgroundColor : '#F3F4F6', cursor : 'pointer'}} onClick={()=>toggleStudent(index_student)}>
															<td className='td-fit-content py-0'>
																<div className='d-flex align-items-center' style={{height : '3rem', color : '9FA2B4'}}>
																	{data_student.user.name}
																	<i className={"ml-3 fas fa-chevron-" + (data_student.is_show ? 'up' : 'down')}></i>
																</div>
															</td>
															<td className='py-0' colSpan={date_arr.length + 2}></td>
														</tr>

														{
															data_student.is_show &&
															reward_arr.map((data_reward, index_reward)=>
																<tr key={index_reward}>
																	<td className=''>{data_reward.name}</td>
																	<td className='text-center'>
																		{
																			arr_point[data_student.id] != null &&
																			arr_point[data_student.id][data_reward.id] != null &&
																			arr_point[data_student.id][data_reward.id]['total'] != null &&
																			<span className={"badge badge-pill p-1 px-2 rounded badge-success"}>
																				{arr_point[data_student.id][data_reward.id]['total']}
																			</span>
																		}
																	</td>

																	{
																		date_arr.map((data_date, index_date)=>
																			<td className='text-center' key={index_date}>
																				{
																					arr_point[data_student.id] != null &&
																					arr_point[data_student.id][data_reward.id] != null &&
																					arr_point[data_student.id][data_reward.id][data_date.id] != null ?
																						<span className={"badge badge-pill p-1 px-2 rounded badge-" + (
																							arr_point[data_student.id][data_reward.id][data_date.id].amount === '0' ? 'danger' :
																							arr_point[data_student.id][data_reward.id][data_date.id].amount === '1' ? 'success' :
																							arr_point[data_student.id][data_reward.id][data_date.id].amount === 'P' ? 'info' : ''
																						)}>
																							{arr_point[data_student.id][data_reward.id][data_date.id].amount}
																						</span>
																					:
																					<span className={"badge badge-pill p-1 px-2 rounded badge-danger"}>{0}</span>
																				}
																			</td>
																		)
																	}
																</tr>
															)
														}
													</>
												)
											}
										</tbody>
									</table>
								</div>
							}
						</div>
					</div>

				</div>
			</div>

			<AttendanceRewardModal
				student_arr={class_student_arr}
				reward_arr={reward_arr}
				onSubmit={(date) => onSubmitAttendanceReward(date)}/>
		</div>
	)
}